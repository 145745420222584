import React from "react";
import "./App.css";
// import { GraphQLClient, gql } from "graphql-request";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, update } from "firebase/database";

import { ProductHistory } from "./ProductHistory";
import { Search } from "./Search";
import { useLocalStorage } from "./hooks/useLocalStorage";
const CryptoJS = require("crypto-js");

//https://mac-blog.org.ua/webrtc-one-to-one-without-signaling-server
// const HASURA_API_ENDPOINT = "https://tenfootshop-db.hasura.app/v1/graphql";

const firebaseConfig = {
  databaseURL:
    "https://wonderbuy-e292f-default-rtdb.europe-west1.firebasedatabase.app/",
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
const db = getDatabase(app);

const getUrlParameter = function getUrlParameter(sParam: string) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};

// const getGraphqlClient = (): GraphQLClient => {
//   const graphQLClient = new GraphQLClient(HASURA_API_ENDPOINT, {
//     headers: {
//       // authorization: 'Bearer MY_TOKEN',
//       "x-hasura-admin-secret":
//         "MMff7RU3uci54O2dEnbRf1usWOX46YchpRsa3WGvA52WdAiz5Ys2euClFEoPNDA1",
//     },
//   });
//   return graphQLClient;
// };

const PairingManager = () => {
  const urlPairingId = getUrlParameter("i") || "";
  const urlKey = getUrlParameter("k") || "";
  const [pairingId] = useLocalStorage("pairingId", urlPairingId);
  const [key] = useLocalStorage("pairingKey", urlKey);

  const connectionRef = React.useRef<RTCPeerConnection | null>();

  // const pc = new RTCPeerConnection({
  //   iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
  // });
  const [status, setStatus] = React.useState("Offered");
  let channel = null;
  const channelRef = React.useRef<any>(channel);
  // const initialProducts = [];
  // const initialProducts = [
  //   {
  //     data: {
  //       url: "test",
  //       title: "ROLLER FREERIDE ADULTE MF500 BLEU BLANC",
  //       images: [
  //         {
  //           url: "https://contents.mediadecathlon.com/p2075913/k$3f66ca0b9b6e1e73b228b10e1a30c8e5/sq/roller-freeride-adulte-mf500-bleu-blanc.jpg?format=auto&f=969x969",
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     data: {
  //       url: "test",
  //       title: "PRoduit 1",
  //       images: [{ url: "test" }],
  //     },
  //   },
  //   {
  //     data: {
  //       url: "test",
  //       title: "PRoduit 1",
  //       images: [{ url: "test" }],
  //     },
  //   },
  //   {
  //     data: {
  //       url: "test",
  //       title: "PRoduit 1",
  //       images: [{ url: "test" }],
  //     },
  //   },
  // ];
  const getPairingDbRef = React.useCallback(
    () => ref(db, `/pairing/${pairingId}`),
    [pairingId]
  );
  const getPairingOfferDbRef = React.useCallback(
    () => ref(db, `/pairing/${pairingId}/offer`),
    [pairingId]
  );

  React.useEffect(() => {
    const dbref = getPairingOfferDbRef();
    onValue(dbref, (snapshot) => {
      const data = snapshot.val();
      if (data === null) {
        // invalid ref
        return;
      }
      if (status !== "Connected" && data) {
        (async () => {
          const decryptedData = CryptoJS.AES.decrypt(data, key);
          const offer = decryptedData.toString(CryptoJS.enc.Utf8);

          await step_2_accept_remote_offer(JSON.parse(offer));
          await step_3_create_answer();
        })();
      }
    });
  }, [status, getPairingDbRef]);

  const [products, setProducts] = React.useState<Array<any>>([]);
  const [settings, setSettings] = React.useState<any>({});

  const ondatachannel = React.useCallback(
    (event) => {
      console.log("ondatachannel");
      channel = event.channel;
      channelRef.current = channel;
      setStatus("Connected");

      // channel.onopen = event => console.log('onopen', event);
      // channel.onmessage = event => console.log('onmessage', event);
      channel.onmessage = (event: any) => processMessage(event.data);
    },
    [setStatus]
  );

  const processMessage = React.useCallback(
    (message: string) => {
      const { action, payload } = JSON.parse(message);
      console.log("onmessage", action, payload);
      if (action === "products") {
        console.log("products updated", payload);
        setProducts(payload.products);
      } else if (action === "settings") {
        setSettings(payload.settings);
      }
    },
    [setSettings, setProducts]
  );

  async function step_2_accept_remote_offer(offer: RTCSessionDescriptionInit) {
    if (connectionRef.current) {
      // destroy
      connectionRef.current.close();
      connectionRef.current = null;
    }

    connectionRef.current = new RTCPeerConnection({
      iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
    });
    connectionRef.current.ondatachannel = ondatachannel;

    const connection = connectionRef.current;
    console.log(connection);
    // const client = getGraphqlClient();
    // const query = gql`
    //   query getPairing($id: uuid!) {
    //     pairing(where: { id: { _eq: $id } }) {
    //       id
    //       offer
    //     }
    //   }
    // `;
    // const variables = {
    //   id: pairingId,
    // };
    // const data = await client.request(query, variables);
    // const offer = JSON.parse(data.pairing[0].offer);
    console.log(offer);
    await connectionRef.current.setRemoteDescription(offer);

    connection.onconnectionstatechange = () => {
      const connectionStatus = connection.connectionState;
      if (["disconnected", "failed", "closed"].includes(connectionStatus)) {
        console.log("pairing disconnected");
        // setStatus("Closed");

        //let peer reconnect with  new offer
        // setTimeout(() => {
        setStatus("Closed");
        // }, 2000);
      }
    };
  }

  async function step_3_create_answer() {
    const connection = connectionRef.current;
    if (!connection) return;

    connection.onicecandidate = async (event) => {
      // console.log('onicecandidate', event)
      if (!event.candidate) {
        const answer = CryptoJS.AES.encrypt(
          JSON.stringify(connection.localDescription),
          key
        ).toString();

        update(getPairingDbRef(), {
          answer,
        });

        // const client = getGraphqlClient();
        // const query = gql`
        //   mutation updatePairing($answer: String!, $id: uuid!) {
        //     update_pairing(
        //       _set: { answer: $answer }
        //       where: { id: { _eq: $id } }
        //     ) {
        //       returning {
        //         id
        //       }
        //     }
        //   }
        // `;
        // const variables = {
        //   answer,
        //   id: pairingId,
        // };
        // const data = await client.request(query, variables);
      }
    };

    const answer = await connection.createAnswer();
    await connection.setLocalDescription(answer);
  }

  async function sendMessage(message: any) {
    if (channelRef.current) channelRef.current.send(JSON.stringify(message));
  }

  // React.useEffect(() => {
  //   (async () => {
  //     // await step_2_accept_remote_offer();
  //     // await step_3_create_answer();
  //   })();
  // }, [setStatus]);

  const handleShowTvProduct = (url: string, product: any) => {
    sendMessage({ action: "showproduct", payload: { url, product } });
  };

  const processWindowMessage = (event: any) => {
    // if (event.origin !== "http://example.org:8080") return;
    console.log("processWindowMessage", event);
    const data = JSON.parse(event.data);
    if (data.action === "cast") {
      const { url, product } = data.payload;
      handleShowTvProduct(url, product);
    }
  };

  React.useEffect(() => {
    if (!pairingId) return;

    window.addEventListener("message", processWindowMessage, false);
    return () => window.removeEventListener("message", processWindowMessage);
  }, [pairingId]);

  const canSearch = !!settings?.search?.baseUrl;

  const prettyStatus =
    status === "Connected"
      ? "Connected"
      : status === "Offered"
      ? "Connecting"
      : "Disconnected";

  const color =
    status === "Connected" ? "green" : status === "Offered" ? "orange" : "red";

  if (!pairingId) {
    return null;
  }

  return (
    <div>
      <div
        className="status"
        style={{ backgroundColor: color }}
        data-status={status}
      >
        {prettyStatus}
      </div>
      {status === "Closed" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <a onClick={() => window.location.reload()}>
            <svg
              height={100}
              width={100}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#fff"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
          </a>
        </div>
      )}
      {status === "Connected" && (
        <div>
          <Search
            disabled={!canSearch}
            onSubmit={(keywords: string) =>
              sendMessage({ action: "search", payload: { keywords, settings } })
            }
            placeholder={canSearch ? `Search ${settings.name}` : ""}
          />
        </div>
      )}
      {status === "Connected" && (
        <div>
          <ProductHistory
            products={products}
            handleShowTvProduct={handleShowTvProduct}
          />
        </div>
      )}
    </div>
  );
};

function App() {
  return (
    <div id="container">
      <PairingManager />
    </div>
  );
}

export default App;
