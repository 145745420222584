import React from "react";

export const Search = ({
  onSubmit,
  disabled,
  placeholder,
}: {
  onSubmit: (kw: string) => void;
  disabled: boolean;
  placeholder: string;
}) => {
  const [keywords, setKeywords] = React.useState("");
  const [focus, setFocus] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeywords(event.target.value);
  };

  const handleSubmit = React.useCallback(() => {
    if (keywords.length > 0) onSubmit(keywords);
  }, [keywords, onSubmit]);

  const handleFocus = () => {
    setKeywords("");
    setFocus(true);
  };

  const handleBlur = () => {
    handleSubmit();
    setFocus(false);
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      className={focus ? "search search--focused2" : "search"}
      style={{ opacity: disabled ? 0 : 1 }}
    >
      {/* {true && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )} */}
      <input
        name="search"
        enterKeyHint="search"
        disabled={disabled}
        type="search"
        placeholder={placeholder}
        value={keywords}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </form>
  );
};
