import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

export const ProductHistory = ({
  products,
  handleShowTvProduct,
}: {
  products: Array<any>;
  handleShowTvProduct: (url: string, product: any) => void;
}) => {
  const options = {
    // type: "loop",
    drag: "free" as any,
    // perPage: 3,
    autoWidth: true,
    arrows: false,
    pagination: false, //true,
    lazyLoad: "nearby" as any,
  };
  return (
    <>
      {products.length === 0 ? null : (
        <Splide options={options}>
          {products.reverse().map((product: any) => {
            return (
              <SplideSlide key={product.url}>
                <div key={product.url} className="product">
                  <a href={product.url} target="_blank" rel="noreferrer">
                    <div
                      style={{
                        height: 100,
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <img
                        alt={product.title}
                        data-splide-lazy={product.imageUrl}
                        width="auto"
                        height="100"
                      />
                    </div>
                    <h2>{product.title}</h2>
                  </a>

                  <div className="tvactions">
                    <button
                      onClick={() => handleShowTvProduct(product.url, product)}
                    >
                      <svg
                        height={20}
                        width={20}
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0"
                        />
                      </svg>{" "}
                      <div>TV</div>
                    </button>
                  </div>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      )}
    </>
  );
};
